export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cilChartPie',
        // badge: {
        //   color: 'primary',
        //   text: 'NEW'
        // }
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Operations']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Stock',
        to: '/admins/StockList',
        icon: 'cilUser'
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Parameters',
        route: '/parameters',
        icon: 'cil-layers',
        _children: [{
          _name: 'CSidebarNavItem',
          name: 'Invoice',
          to: '/admins/disburstmentList',
          icon: 'cil-spreadsheet',

        },
        {
          _name: 'CSidebarNavItem',
          name: 'Customer',
          to: '/admins/OperatorList',
          icon: 'cilUser'
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Suppliers',
          to: '/admins/StockList',
          icon: 'cilUser'
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Forwarder',
          to: '/admins/StockList',
          icon: 'cilUser'
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Banks',
          to: '/admins/StockList',
          icon: 'cilUser'
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Brands',
          to: '/admins/BrandList',
          icon: 'cilUser'
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Sub Companies',
          to: '/admins/SubCompanyList',
          icon: 'cilUser'
        },

        ]
      },


      {
        _name: 'CSidebarNavDropdown',
        name: 'Reports',
        route: '/disburstment',
        icon: 'cil-layers',
        _children: [{
          _name: 'CSidebarNavItem',
          name: 'Disbursement',
          to: '/admins/disburstmentList',
          icon: 'cil-spreadsheet',

        },

        ]
      },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Showroom',
      //   to: '/admins/OperatorList',
      //   icon: 'cilUser'
      // },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Sales Agent']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Vehicles',
        to: '/admins/OperatorList',
        icon: 'cilUser'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Configuration']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Company',
        to: '/admins/Company',
        icon: 'cilUser'
      },


      {
        _name: 'CSidebarNavTitle',
        _children: ['Users']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Roles',
        to: '/admins/RoleList',
        icon: 'cilUser'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Operators',
        to: '/admins/OperatorList',
        icon: 'cilUser'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Sales Agents',
        to: '/admins/AgentList',
        icon: 'cilUser'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Applications',
        to: '/admins/ApplicationList',
        icon: 'cilSpreadsheet'
      },
      // {
      //   _name: 'CSidebarNavTitle',
      //   _children: ['Configuration'],
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Service Provider',
      //   to: '/admins/serviceProviderList',
      //   icon: 'cilGlobeAlt'
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Packages',
      //   to: '/admins/packageList',
      //   icon: 'cilLaptop'
      // },


    ]
  }
]